
import * as ipxRuntime$VjUzDENllf from '/usr/src/app/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [
    "a10.acc.ece.esolut.org",
    "aez.acc.ece.esolut.org",
    "agd.acc.ece.esolut.org",
    "gnu.acc.ece.esolut.org",
    "nel.acc.ece.esolut.org",
    "www.myce.de",
    "restapi.acc.ece.esolut.org",
    "aez.stage.ece.esolut.org",
    "a10.stage.ece.esolut.org",
    "a10.stage.ece.esolut.org",
    "agd.stage.ece.esolut.org",
    "restapi.acc.ece.esolut.org",
    "aez.stage.ece.esolut.org",
    "a10.stage.ece.esolut.org",
    "a10.stage.ece.esolut.org",
    "restapi.myce.de",
    "restapi.myce.de",
    "www.alstertal-einkaufszentrum.de",
    "www.nova-shopping.de",
    "www.altmarkt-galerie-dresden.de",
    "nel.stage.ece.esolut.org",
    "nel.stage.ece.esolut.org",
    "restapi.stage.ece.esolut.org"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$VjUzDENllf, defaults: {} }
}
        